<template>
  <main
    class="min-h-[100dvh]"
    :style="cssVariables"
  >
    <AppStyleTag :content="customFontCss" />
    <slot />

    <client-only>
      <Modal />
      <LoadingDialog />
      <PaymentResponseSuccessDialog />
    </client-only>
  </main>
</template>

<script setup>
import Color from 'color';

// Computed
const cssVariables = computed(() => {
  let primaryColor = '#000000';
  try {
    primaryColor = Color(useWorkspaceStore().identity?.primaryColor || '#19A43A').hex();
  } catch (error) {
    console.error('Invalid primaryColor:', primaryColor, error);
  }

  return [
    `--color-primary-50: ${Color(primaryColor).mix(Color('#fff'), 0.96).hex()}`,
    `--color-primary-100: ${Color(primaryColor).mix(Color('#fff'), 0.88).hex()}`,
    `--color-primary-200: ${Color(primaryColor).mix(Color('#fff'), 0.66).hex()}`,
    `--color-primary-300: ${Color(primaryColor).mix(Color('#fff'), 0.44).hex()}`,
    `--color-primary-400: ${Color(primaryColor).mix(Color('#fff'), 0.22).hex()}`,
    `--color-primary-500: ${Color(primaryColor).hex()}`,
    `--color-primary-500/15: ${Color(primaryColor).fade(0.85).string()}`,
    `--color-primary-500-15: ${Color(primaryColor).fade(0.85).string()}`,
    `--color-primary-600: ${Color(primaryColor).mix(Color('#000'), 0.22).hex()}`,
    `--color-primary-700: ${Color(primaryColor).mix(Color('#000'), 0.44).hex()}`,
    `--color-primary-800: ${Color(primaryColor).mix(Color('#000'), 0.66).hex()}`,
    `--color-primary-900: ${Color(primaryColor).mix(Color('#000'), 0.88).hex()}`,
    `--el-color-primary: ${Color(primaryColor).hex()}`,
  ];
});


const customFontCss = computed(() => {
  if (useWorkspaceStore().identity?.font) {
    return `
      @font-face {
        font-family: 'CustomPageFont';
        font-style: normal;
        font-weight: 400;
        src: url('${useWorkspaceStore().identity.font.regular}') format('woff');
      }
      @font-face {
        font-family: 'CustomPageFont';
        font-style: normal;
        font-weight: 500;
        src: url('${useWorkspaceStore().identity.font.medium}') format('woff');
      }
      @font-face {
        font-family: 'CustomPageFont';
        font-style: normal;
        font-weight: 600;
        src: url('${useWorkspaceStore().identity.font.bold}') format('woff');
      }
      @font-face {
        font-family: 'CustomPageFont';
        font-style: normal;
        font-weight: 700;
        src: url('${useWorkspaceStore().identity.font.bold}') format('woff');
      }`;
  }
  return '';
});

provide('customFontCss', customFontCss);

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});
</script>
